import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: 'ebf925a8-8353-4915-94c4-0167c9c6e413',
    development: '46d24b3b-6197-4904-a2be-69772f79449f',
  },
}

export default {
  name: {
    nb: 'vg2 Baker og konditor',
  },
  slug: 'bkfag',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-KV4JNCC',
  },
  assets: {
    logo: '/themes/bkfag/images/logo.svg',
    favicon: '/themes/bkfag/favicon.ico',
  },
  dbok: {
    nb: [
      {
        title: 'Bakerboka',
        link: 'https://reader.dbok.no/#/book/624d9563bcfdf000144b21d1',
      },
      {
        title: 'Konditorboka',
        link: 'https://reader.dbok.no/#/book/623b2f478f45ec0012d4de35',
      },
    ],
    nn: [
      {
        title: 'Bakerboka',
        link: 'https://reader.dbok.no/#/book/624d9563bcfdf000144b21d1',
      },
      {
        title: 'Konditorboka',
        link: 'https://reader.dbok.no/#/book/623b2f478f45ec0012d4de35',
      },
    ],
  },
  styles: [
    '/themes/bkfag/css/bkfag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til vg2 Baker og konditor</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til vg2 Bakar og konditor</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Fagbegrep',
        to: '/concepts/fagbegrep',
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter'
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no',
      },
      {
        title: 'Rettelser',
        to: '/files/rettelser',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      }
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/fagbegrep',
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter'
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no',
      },
      {
        title: 'Rettingar',
        to: '/files/rettelser',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      }
    ],
  },
}
